import { ButtonHTMLAttributes, DetailedHTMLProps, Fragment } from 'react'

import { Theme, css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { breakpoint } from '@/theme'
import BadgeComponent from '@components/button/badge'
import SpinnerComponent from '@components/button/spinner'
import { TEST_IDS } from '@components/button/test/constants'

interface PropTypes extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  // Button Props
  variant?: string
  children?: any
  label?: string
  icon?: any
  backgroundColor?: string
  margin?: string
  padding?: string
  width?: string
  fontFamily?: string
  labelColor?: string
  price?: string | number | JSX.Element
  salePrice?: string
  loading?: boolean

  // Legacy Props
  notification?: string | number
  isLoading?: boolean
  buttonType?: string
  style?: any
}

interface VariantStylesProps {
  variant?: string
  theme?: Theme
}

const getVariantStyles = ({ variant = 'variant-1', theme }: VariantStylesProps) => {
  switch (variant) {
    // Main CTA button, default button background w/ black text
    case 'variant-1': {
      return css`
        justify-content: center;
        align-items: center;
        padding: 22px ${theme.spacing.xxl}px;
        background-color: ${theme.colors.button};
        color: ${theme.colors.text};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);

        @media (max-width: ${breakpoint.max.md}px) {
          padding: ${theme.spacing.medium}px ${theme.spacing.xxl}px;
        }
      `
    }
    // Secondary, shorter CTA button, default white background w/ black text
    case 'variant-2': {
      return css`
        justify-content: center;
        align-items: center;
        padding: 12px ${theme.spacing.xxl}px;
        background-color: ${theme.colors.white};
        color: ${theme.colors.text};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
      `
    }
    // Product card add to cart button with icon
    case 'variant-3': {
      return css`
        width: 100%;
        padding: 5px ${theme.spacing.small}px;
        background-color: ${theme.colors.subYellow};
        color: ${theme.colors.text};
        font-family: ${theme.typography.font.sim};
        font-size: ${theme.typography.size.desktop.body3}px;
        border: none;
        border-radius: ${theme.border.borderRadius.default}px;
      `
    }
    // Tags & Add code buttons
    case 'variant-4': {
      return css`
        padding: ${theme.spacing.smaller}px ${theme.spacing.small}px;
        background-color: ${theme.colors.white};
        color: ${theme.colors.text};
        font-family: ${theme.typography.font.sim};
        font-size: ${theme.typography.size.desktop.body3}px;
        border: 1px solid ${theme.colors.border};
        border-radius: ${theme.border.borderRadius.default}px;
      `
    }
    // Full width add to bag button
    case 'variant-5': {
      return css`
        width: 100%;
        padding: ${theme.spacing.default}px ${theme.spacing.large}px;
        background-color: ${theme.colors.subYellow};
        color: ${theme.colors.text};
        font-family: ${theme.typography.font.npBold};
        font-size: ${theme.typography.size.desktop.body3}px;
        border: none;
        border-radius: ${theme.border.borderRadius.default}px;
        @media (max-width: ${breakpoint.max.md}px) {
          padding: 24px ${theme.spacing.large}px;
        }
      `
    }
    // Full width add to bag button 2
    case 'variant-6': {
      return css`
        width: 100%;
        padding: ${theme.spacing.medium}px ${theme.spacing.large}px ${theme.spacing.xxl}px;
        background-color: ${theme.colors.subYellow};
        color: ${theme.colors.text};
        font-family: ${theme.typography.font.npBold};
        font-size: ${theme.typography.size.desktop.body3}px;
        border: none;
        border-radius: ${theme.border.borderRadius.default}px;
      `
    }
    // Danger button, default danger background w/ white text
    case 'variant-7': {
      return css`
        background-color: ${theme.colors.danger};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
        color: ${theme.colors.textInverse};
        padding: ${theme.spacing.default}px ${theme.spacing.xxl}px;
      `
    }
    case 'variant-8': {
      return css`
        width: 100%;
        padding: ${theme.spacing.default}px;
        background-color: ${theme.colors.subYellow};
        color: ${theme.colors.text};
        font-family: ${theme.typography.font.npBold};
        font-size: ${theme.typography.size.desktop.body3}px;
        border: none;
        border-radius: ${theme.border.borderRadius.default}px;
        text-align: left;
        white-space: nowrap;
        &:disabled {
          color: ${theme.colors.text};
        }
        @media (max-width: ${breakpoint.max.md}px) {
          padding: 24px;
        }
      `
    }
    // Place Order button
    case 'variant-9': {
      return css`
        padding: ${theme.spacing.default}px;
        background-color: ${theme.colors.button};
        color: ${theme.colors.text};
        width: 100%;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);

        &:disabled {
          box-shadow: none;
          background-color: ${theme.colors.subYellowDisabled};
          border-color: ${theme.colors.lighterGrey};
          color: ${theme.colors.lighterGrey};
        }

        > span {
          flex: 1;
        }
      `
    }
    // Button that looks like a Link
    case 'variant-10': {
      return css`
        appearance: none;
        background: none;
        display: block;
        border: 0;
        margin: 0 auto;
        padding: 0;
        text-decoration: underline;
        text-transform: lowercase;
        font-size: ${theme.typography.size.desktop.body3}px;
        font-family: ${theme.typography.font.simBold};
        color: ${theme.colors.link};

        &:disabled {
          background-color: transparent;
          color: ${theme.colors.link};
        }
      `
    }
    // Inverse Danger button
    case 'variant-11': {
      return css`
        background-color: ${theme.colors.white};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
        color: ${theme.colors.danger};
        padding: ${theme.spacing.medium}px ${theme.spacing.xxl}px;
      `
    }
    // Thin, full-width, drop-shadow
    case 'variant-12': {
      return css`
        width: 100%;
        padding: ${theme.spacing.smaller}px ${theme.spacing.small}px;
        background-color: ${theme.colors.subYellow};
        font-family: ${theme.typography.font.sim};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
      `
    }
    // Inverse main CTA button, dark/black background w/ white text
    case 'variant-13': {
      return css`
        padding: 22px ${theme.spacing.xxl}px;
        background-color: ${theme.colors.black};
        color: ${theme.colors.textInverse};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);

        @media (max-width: ${breakpoint.max.md}px) {
          padding: ${theme.spacing.medium}px ${theme.spacing.xxl}px;
        }
      `
    }
    // Modal CTA button, full width, 60px height
    case 'variant-14': {
      return css`
        width: 100%;
        padding: 22px ${theme.spacing.xs}px;
        background-color: ${theme.colors.button};
        color: ${theme.colors.text};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
      `
    }
    // CTA Load more button, sign up page button w/ width prop, 56px height, 100% width on mobile only
    case 'variant-15': {
      return css`
        padding: ${theme.spacing.medium}px 68px;
        background-color: ${theme.colors.button};
        color: ${theme.colors.text};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
        @media (max-width: ${breakpoint.max.lg}) {
          width: 100%;
        }
      `
    }

    // Modal CTA button, thin, full-width, no drop-shadow
    case 'variant-16': {
      return css`
        width: 100%;
        height: 56px;
        padding: ${theme.spacing.smaller}px ${theme.spacing.small}px;
        background-color: ${theme.colors.subYellow};
        font-family: ${theme.typography.font.sim};
        span {
          margin: auto;
        }
        &:disabled {
          background-color: ${theme.colors.subYellowDisabled};
          border-color: ${theme.colors.lighterGrey};
          color: ${theme.colors.lighterGrey};
        }
      `
    }
    // Inverse Danger button - 56px height, 100% width w/ padding for Icon prop
    case 'variant-17': {
      return css`
        width: 100%;
        background-color: ${theme.colors.white};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
        color: ${theme.colors.danger};
        padding: 14px ${theme.spacing.xxl}px;
      `
    }
    // Order status need help button, 56px height, width calc for container
    case 'variant-18': {
      return css`
        padding: ${theme.spacing.medium}px 68px;
        background-color: ${theme.colors.white};
        color: ${theme.colors.text};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
        width: 100%;
        @media (min-width: ${breakpoint.min.md}px) {
          width: calc(100% - 4px);
        }
      `
    }
    // Thin, full-width on mobile only, drop-shadow, small font
    case 'variant-19': {
      return css`
        width: 100%;
        padding: ${theme.spacing.smaller}px ${theme.spacing.small}px;
        background-color: ${theme.colors.subYellow};
        font-family: ${theme.typography.font.sim};
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
        font-size: ${theme.typography.size.desktop.body4}px;
      `
    }
    default: {
      return css`
        padding: ${theme.spacing.medium}px ${theme.spacing.xxl}px;
        background-color: transparent;
        color: ${theme.colors.text};
      `
    }
  }
}

/**
 * Primary UI components for user interaction
 */

const StyledButton = styled.button<PropTypes>`
  cursor: pointer;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.font.sim};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.border.borderRadius.default};
  display: inline-flex;
  line-height: 1;
  &:active:not(:disabled) {
    box-shadow: none;
  }
  &:disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.textDisabled};
    background-color: ${(props) => props.theme.colors.backgroundDisabled};
  }

  ${(props) => getVariantStyles(props)}
  // override padding if needed
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
    // override margin if needed
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
  // override padding if needed
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  // override background color if needed
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
  // override font-family if needed
  ${({ fontFamily }) =>
    fontFamily &&
    css`
      font-family: ${fontFamily};
    `}

  // override label color if needed
  ${({ labelColor }) =>
    labelColor &&
    css`
      color: ${labelColor};
    `}
`

const IconContainer = styled.span`
  margin-left: 10px;
`
const Container = styled.span<{ hasPrice: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ hasPrice }) => (hasPrice ? 'space-between' : 'center')};
  align-items: center;
`
const Item = styled.span`
  display: flex;
`
const PriceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

/**
 * Export component for implementation
 */

export const Button = ({
  children,
  label,
  icon,
  price,
  salePrice,
  loading: isLoading,
  disabled,
  notification,
  ...rest
}: PropTypes) => {
  // Set theme with useTheme hook
  const theme = useTheme()

  return (
    <StyledButton disabled={disabled} {...rest}>
      {price ? (
        <Container hasPrice={!!price}>
          {notification && <BadgeComponent count={notification} />}
          {isLoading ? (
            <SpinnerComponent pixelSize={24} />
          ) : (
            <Fragment>
              <PriceItem>
                {(label || children) && <div>{label || children}</div>}
                <div style={{ fontFamily: theme.typography.font.simBold }}>
                  {/* price and salePrice logic */}
                  {price && !salePrice ? (
                    <span>{price}</span>
                  ) : (
                    <Fragment>
                      <span style={{ textDecoration: 'line-through' }}>{price}</span>
                      <span
                        style={{
                          color: theme.colors.danger,
                          marginLeft: theme.spacing.small
                        }}>
                        {salePrice}
                      </span>
                    </Fragment>
                  )}
                </div>
              </PriceItem>
              {icon && <PriceItem style={{ marginLeft: theme.spacing.small }}>{icon}</PriceItem>}
            </Fragment>
          )}
        </Container>
      ) : (
        <Container hasPrice={!!price}>
          {notification && <BadgeComponent count={notification} />}
          {isLoading ? (
            <Item>
              <SpinnerComponent pixelSize={24} />
            </Item>
          ) : (
            <Fragment>
              <Item>{label || children}</Item>
              {icon && (
                <Item>
                  <IconContainer data-e2eid={TEST_IDS.ICON}>{icon}</IconContainer>
                </Item>
              )}
            </Fragment>
          )}
        </Container>
      )}
    </StyledButton>
  )
}

Button.defaultProps = {
  variant: 'variant-1'
}
